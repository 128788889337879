<template>
  <section class="bg-white">
    <div v-if="showErrorMsg" class="mb-4 flex items-center rounded-lg bg-red-50 p-4 text-red-800" role="alert">
      <div class="text-xl">Ups, beim Versand ist etwas schiefgelaufen. Bitte versuch es später noch einmal.</div>
      <button
        type="button"
        class="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-red-50 p-1.5 text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-red-400"
        aria-label="Close"
        @click="showErrorMsg = false">
        <span class="sr-only">Close</span>
        <SvgoXMark class="stroke-2 text-xl" />
      </button>
    </div>

    <form class="mx-auto flex max-w-screen-md flex-col justify-center gap-4 md:gap-6 lg:gap-8" @submit.prevent="submit">
      <div class="relative">
        <input
          id="name"
          v-model.trim="name"
          type="text"
          class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0"
          placeholder=" "
          required />
        <label
          for="name"
          class="pointer-events-none absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
          >Name*</label
        >
      </div>

      <div class="relative">
        <input
          id="phone"
          v-model.trim="phone"
          type="text"
          class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0"
          placeholder=" "
          required />
        <label
          for="phone"
          class="pointer-events-none absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
          >Telefonnummer*</label
        >
      </div>

      <div class="relative">
        <input
          id="email"
          v-model.trim="email"
          type="email"
          class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0"
          placeholder=" " />
        <label
          for="email"
          class="pointer-events-none absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
          >E-Mail-Adresse</label
        >
      </div>

      <div class="relative">
        <select
          id="focus"
          v-model.trim="focus"
          class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0">
          <option value="Keine Angabe">Schwerpunkt auswählen</option>
          <option value="Herz-Kreislauf">haelsi Vorsorgecheck mit Schwerpunkt Herz-Kreislauf</option>
          <option value="Rücken & Gelenke">haelsi Vorsorgecheck mit Schwerpunkt Rücken &amp; Gelenke</option>
          <option value="Stress-Prävention">haelsi Vorsorgecheck mit Schwerpunkt Stress-Prävention</option>
          <option value="WS - Besser Leben">Wiener Städtische "Besser Leben" Vorsorgecheck</option>
          <option value="UNIQA Vitalcheck">UNIQA Vitalcheck</option>
          <option value="UNIQA Vorsorgeprogramm">UNIQA Vorsorgeprogramm</option>
          <option value="Generali Rundum Gesund">Generali Rundum Gesund</option>
        </select>
        <label
          for="focus"
          class="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
          >Vorsorge Schwerpunkt</label
        >
      </div>

      <div class="relative">
        <input
          id="timeframe"
          v-model.trim="timeframe"
          type="text"
          class="border-1 peer block w-full appearance-none rounded-lg border-brick-700 bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0"
          placeholder=" " />
        <label
          for="timeframe"
          class="pointer-events-none absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform rounded-md bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-yellow-600"
          >Bevorzugte Wochentage & Tageszeiten</label
        >
      </div>

      <p class="text-sm text-gray-500">
        Durch Klicken auf
        <span class="font-medium">"Kontaktiere uns"</span> erkläre ich mich mit den
        <NuxtLink
          to="/agb"
          no-rel="no-rel"
          no-prefetch="no-prefetch"
          class="dark:text-primary-500 text-gray-900 hover:underline"
          target="_blank"
          >Allgemeinen Geschäftsbedingungen</NuxtLink
        >
        sowie mit der Verarbeitung meiner persönlichen Daten gemäß der
        <NuxtLink
          to="/datenschutz"
          no-rel="no-rel"
          no-prefetch="no-prefetch"
          class="dark:text-primary-500 text-gray-900 hover:underline"
          target="_blank"
          >Datenschutzvereinbarung</NuxtLink
        >
        einverstanden.
      </p>

      <button
        type="submit"
        :disabled="isSubmitting"
        class="flex w-[100%] justify-center gap-3 self-center rounded-full bg-yellow px-4 py-2 text-xl transition hover:bg-yellow-300 hover:text-gray-900 disabled:cursor-not-allowed disabled:bg-gray-900 disabled:text-gray-100">
        Kontaktiere uns
      </button>
    </form>
  </section>
</template>

<script setup lang="ts">
import { ref } from "vue";
const config = useRuntimeConfig();

const name = ref("");
const phone = ref("");
const email = ref("");
const focus = ref("Keine Angabe");
const timeframe = ref("");

const isSubmitting = ref(false);
const showErrorMsg = ref(false);

const emit = defineEmits(["onSubmitSuccess"]);

async function submit() {
  isSubmitting.value = true;
  showErrorMsg.value = false;

  const customMessage = `Vorsorge Schwerpunkt: ${focus.value}

Bevorzugte Wochentage & Tageszeiten:
${timeframe.value}
`;

  try {
    await $fetch(`${config.public.apiUrl}/api/contact-messages`, {
      body: {
        data: {
          email: email.value ? email.value : "keine.angabe@example.com",
          form: {
            type: "Rückruf_Vorsorge",
          },
          lastName: name.value,
          message: customMessage,
          phone: phone.value,
        },
      },
      method: "POST",
    });

    // re-enable submit button
    isSubmitting.value = false;

    // reset form
    name.value = "";
    phone.value = "";
    email.value = "";
    focus.value = "";
    timeframe.value = "";

    emit("onSubmitSuccess", true);
    // eslint-disable-next-line unused-imports/no-unused-vars
  } catch (_error) {
    isSubmitting.value = false;
    showErrorMsg.value = true;
  }
}
</script>

<style lang="scss" scoped></style>
