<template>
  <!-- Main modal -->
  <div
    v-if="modalStore.isVisible"
    tabindex="-1"
    role="dialog"
    aria-modal="true"
    aria-labelledby="vorsorgeModalLabel"
    aria-describedby="vorsorgeModalDescription"
    class="fixed left-0 right-0 top-0 z-50 flex h-[100%] w-full overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-30 p-4 md:inset-0">
    <div class="relative m-auto w-full lg:max-w-xl">
      <!-- Modal content -->
      <div class="relative flex flex-col rounded-lg bg-white p-6 shadow">
        <!-- Modal header -->
        <div class="flex items-center justify-between rounded-t">
          <h3 v-if="showForm" id="vorsorgeModalLabel" class="text-2xl leading-tight md:text-3xl">
            Vorsorgecheck vereinbaren
          </h3>
          <h3 v-else id="vorsorgeModalLabel" class="text-2xl md:text-3xl">Wir rufen dich an!</h3>
          <button
            type="button"
            class="ml-auto rounded-lg bg-transparent text-gray-900 hover:bg-gray-100"
            @click="toggleModalAndReset">
            <SvgoXMark class="text-4xl" />
            <span class="sr-only">Schließen</span>
          </button>
        </div>
        <div v-if="showForm" id="vorsorgeModalDescription">
          <p class="text-gray-500 md:text-lg xl:text-xl">
            Wir buchen deinen Vorsorgecheck telefonisch für dich. Ruf uns einfach an oder hinterlasse deine Kontaktdaten
            und wir melden uns bei dir!
          </p>
          <a
            href="tel:+4318904520"
            class="flex items-center gap-4 py-4 text-xl text-gray-900 hover:underline md:py-6 md:text-2xl">
            <PhoneIllustrationIcon class="h-9" aria-hidden="true" />
            +43 1 890 45 20</a
          >
          <SharedVorsorgeContact @on-submit-success="showForm = false" />
        </div>
        <p v-else id="vorsorgeModalDescription" class="md:text-lg xl:text-xl">
          Vielen Dank für die Nachricht! Wir melden uns so bald wie möglich.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import PhoneIllustrationIcon from "assets/svg/phoneIllustration.svg?skipsvgo";
import { useVorsorgeModalStore } from "~/store/vorsorgeModal";

const showForm = ref(true);

const toggleModalAndReset = () => {
  modalStore.toggleModal();
  showForm.value = true;
};

const modalStore = useVorsorgeModalStore();
</script>

<style lang="scss" scoped></style>
